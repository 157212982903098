/* src/components/EmailForm.css */
.email-form {
    background: #f7f7f9;
}

.email-form .form-control {
    border-radius: 0.25rem;
}

.email-form .btn-primary {
    background: #007bff;
    border: none;
    transition: background 0.3s ease;
}

.email-form .btn-primary:hover {
    background: #0056b3;
}

.email-form .spinner-border {
    color: #007bff;
}
